.deletemodal__action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: auto;
  gap: 10px;
}

.deletemodal {
  display: flex;
  flex-direction: column;
  /* justify-content: center;
  align-items: center; */
  gap: 30px;
  /* text-align: center; */
}

.deletemodal h3 {
  text-align: left !important;
}
