.admincard {
  width: 250px;
  max-width: 250px;
  height: 330px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* background-color: aqua; */
  border-radius: 10px;
  position: relative;
}
.admincard__edit {
  padding: 3px 5px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
}
.admincard__editAdmin {
  padding: 3px 5px;
  border-radius: 4px;
  position: absolute;
  top: 8px;
  right: 8px;
  cursor: pointer;
  background-color: var(--grey400);
}

.admincard__edit h5,
.admincard__editAdmin h5 {
  color: white;
}

.admincard__img {
  max-width: 250px;
  height: 150px;
  object-fit: cover;
  overflow: hidden;
  border-radius: 10px 10px 0 0;
  align-items: center;
  padding: 10px 5px;
  /* width: 100%; */
}

.admincard__imgcontainer {
  display: flex;
  justify-content: center;
  overflow: 'hidden'
}

.admincard__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 20px;
  overflow: hidden;
  gap: 16px;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
}
.admincard__contentpoints {
  display: flex;
  gap: 5px;
  align-items: center;
}
.admincard__contenthead {
  display: flex;
  width: 210px;
  max-width: 220px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}
.admincard__contentseprator {
  height: 0.3px;
  background-color: #d9d9d9;
}
.admincard__contenthead h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inherit;
  font-size: 14px;
}

.admincard__content h6 {
  color: var(--grey600);
  font-weight: 400 !important;
}
.admincard__content h4 {
  color: var(--grey600);
}

.admincard__content h5 {
  overflow: hidden;
  font-weight: 400 !important;
  color: var(--grey500);
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-width: 100%;
  overflow: hidden;
}

.admincard label {
  font-weight: 400 !important;
  color: var(--grey500);
  margin-right: 10px;
}
