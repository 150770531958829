.questActivity h3 {
  color: white;
  background: linear-gradient(
    90deg,
    #8a73db 0%,
    rgba(138, 115, 219, 0.15) 101.07%,
    rgba(138, 115, 219, 0.15) 101.07%
  );
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 15px;
}

.questActivity__books {
  height: 100%;
  padding: 0px 20px;
  display: flex;
  justify-content: flex-start;
  position: relative;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.questActivity__container{
  background: linear-gradient(
    90.45deg,
    rgba(138, 115, 219, 0.25) 0.07%,
    rgba(90, 131, 231, 0.25) 51.04%,
    rgba(138, 115, 219, 0.25) 99.93%
  );
  border-radius: 5px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px
}

.questActivity__booksleft {
  width: 77px;
  height: 105px;
  transform: skew(-20deg);
  background: #ebf1ff;
  box-shadow: 3px 3px 5px rgba(51, 97, 207, 0.35);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  left: 30px;
}

.questActivity__booktype{
  display: flex;
  flex-direction: row;
  gap: 15px;
  padding-left: 15px;
}

.questActivity__booktoggle h4{
  border-radius: 12px;
  display: flex;
  justify-content: center;
  background: linear-gradient(
    90deg,
    #3361CF 0%,
    #8A73DB 101.07%
  );
  width: 100px;
  padding: 10px;
  color: white;
}

.questActivity__bookinactive h4{
  border-radius: 12px;
  display: flex;
  justify-content: center;
  background: transparent;
  width: 100px;
  padding: 10px;
  color: var(--pblue);
}

.questActivity__bookinactive:hover{
  cursor: pointer;
}

.questActivity__booksright {
  width: 77px;
  height: 105px;
  transform: skew(-20deg);
  background: #ebf1ff;
  box-shadow: 3px 3px 5px rgba(51, 97, 207, 0.35);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 5;
  position: absolute;
  right: 30px;
}
.questActivity__booksrightsquare {
  width: 75px;
  height: 105px;
  transform: skew(20deg);
  background: #ebf1ff;
  position: absolute;
  right: -17px;
  z-index: -1;
  border-radius: 10px;
}
.questActivity__booksleftsquare {
  width: 75px;
  height: 105px;
  transform: skew(20deg);
  background: #ebf1ff;
  position: absolute;
  left: -17px;
  z-index: -1;
  border-radius: 10px;
}

.questActivity__cardslayout {
  position: relative;
  width: 250px;
  height: 100px;
}

.questActivity__cards {
  width: 230px;
  height: 105px;
  background: linear-gradient(
    94.7deg,
    rgba(138, 115, 219, 0.7) -14.81%,
    rgba(51, 97, 207, 0.7) 114.63%
  );
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 0px 10px 10px 35px;
  cursor: pointer;
  transform: skew(-11deg);
}

.questActivity__cards:hover {
  background: linear-gradient(
    94.7deg,
    rgba(138, 115, 219, 0.9) -14.81%,
    rgba(51, 97, 207, 0.9) 114.63%
  );
}

.questActivity__cards h5 {
  transform: skew(11deg);
  color: white;
  opacity: 1 !important;
  position: absolute;
  left: 63px;
  top: 30px;
}
.questActivity__cardsimg {
  width: 85px;
  height: 85px;
  border-radius: 50%;
  padding: 7.5px;
  left: -40px;
  top: -0.256px;
  background: linear-gradient(270deg, #71a0fd 20%, #8a73db 110.53%);
  position: absolute;
  /* display: flex; */
  z-index: 88;
}
.questActivity__cardsimg img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  overflow: hidden;
  border-radius: 50%;
  background: linear-gradient(169.39deg, #ffffff 6.34%, #dddddd 161.99%);
}

.questActivity__bottomcard {
  width: 137px;
  height: 30px;
  bottom: 0px;
  /* right: 6px; */
  left: 87px;
  position: absolute;
  transform: skew(-11deg);
  border-radius: 10px 0px 2px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(92.2deg, #3361cf 10.39%, #8a73db 97.2%);
}

.questActivity__cards p {
  transform: skew(11deg);
  position: absolute;
  color: white;
  font-size: 12px;
  font-weight: 600;
  bottom: 15px;
  right: 23px;
  opacity: 1 !important;
  z-index: 1;
}

.questActivity__containercard {
  display: flex;
  width: 45vw;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: inherit;
  gap: 70px;
  padding: 0px 45px;
}

/* corousel */
.swiper {
  width: 96.5%;
}

.swiper-custom .swiper-slide {
  /* width: auto !important; */
  margin-left: 120px;
}

/* @media screen and (max-width: 1280px) {
  .swiper-custom .swiper-slide {
    margin-left: 120px;
  }
} */

.swiper-custom .swiper-button-next,
.swiper-custom .swiper-button-prev {
  position: absolute;
  color: rgba(0, 0, 0, 0.6);
  -webkit-tap-highlight-color: transparent;
}

.swiper-custom-blogs {
  padding: 15px;
}
.swiper-custom-blogs .swiper-button-next,
.swiper-custom-blogs .swiper-button-prev {
  position: absolute;
  color: white;
  width: 40px;
  height: 40px;
  right: 0;
  border-radius: 50%;
  background-color: var(--background1);
  -webkit-tap-highlight-color: transparent;
}

.swiper-custom .swiper-button-next::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 40px;
  z-index: -1;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    -90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 254, 254, 1) 15%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0.7637648809523809) 85%,
    rgba(255, 255, 255, 0) 100%
  );
}
.swiper-wrapper {
  margin-left: 20px;
}

.swiper-custom .swiper-button-prev::before {
  content: " ";
  position: absolute;
  width: 200px;
  height: 40px;

  z-index: -1;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 254, 254, 1) 15%,
    rgba(255, 255, 255, 1) 28%,
    rgba(255, 255, 255, 1) 75%,
    rgba(255, 255, 255, 0.7637648809523809) 85%,
    rgba(255, 255, 255, 0) 100%
  );
}

.swipe_container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 100px;
  overflow-x: auto;
  overflow-y: hidden;
  flex-wrap: nowrap;
  padding: 0 160px;
  z-index: 1;
}

.swipe_container::-webkit-scrollbar {
  display: none !important;
}
