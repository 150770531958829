.createcard {
  width: 250px;
  height: 330px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  /* background-color: aqua; */
  border-radius: 10px;
}

.createcard__add {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--grey200);
  height: 150px;
  border-radius: 10px 10px 0 0;
}

.createcard__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 20px 30px;
  gap: 16px;
  background: #ffffff;
  border-radius: 0px 0px 10px 10px;
}

.createcard__contenthead {
  display: flex;
  width: 210px;
  max-width: 220px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.createcard__contentseprator {
  height: 0.1px;
  background-color: #d9d9d9;
}
