.viewbook {
    display: grid;
    grid-template-columns: 1fr auto;
    align-content: flex-start;
    gap: 30px;
    margin-top: 20px;
  }

.viewbook__container {
    padding-right: 15px;
    overflow-x: hidden;
  }

.viewbooks__left {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.viewbooks__left h6 {
    color: #747474;
    margin-top: 10px;
}

.viewbook__point_badge {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 10px 20px;
    background-color: #ee723333;
    width: fit-content;
    color: #ee7233;
    font-weight: 600;
    border-radius: 5px;
    margin-top: 15px;
}


.viewbook__point_badge h5 {
    color: #ee7233;
    font-weight: 600;
  }

.viewbook__right {
    /* width: 190px;
    height: 175px; */
    border: 1.5px solid var(--grey300);
    border-radius: 5px;
    padding: 15px;
    position: relative;
  }

  .viewbook__right h6 span {
    color: var(--error);
  }
  
  .viewbook__right img {
    width: 100%;
    height: 100%;
    max-width: 160px;
    max-height: 160px;
    object-fit: cover;
    overflow: hidden;
    margin-top: 4px;
    border-radius: 3px;
  }

  .viewbook__levels {
    margin-top: 30px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .viewbook__level {
    /* max-width: 2000px; */
    width: 100%;
    border: 1px solid var(--grey300);
    border-radius: 10px;
    height: max-content;
    transition: height 0.6s;
  }

  .viewbook__levelHead {
    background-color: var(--slightpurple);
    max-width: 2000px;
    padding: 18px 40px;
    border-radius: 10px;
    height: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    cursor: pointer;
    position: relative;
  }

  .viewbook__levelHead h5 {
    color: var(--pblue);
  }

  .viewbook__levelExpanded {
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .viewbook__topLevel{
    display: grid;
    grid-template-columns: auto 1fr;
    align-content: flex-start;
    gap: 54px;
    margin: 20px 0px;
  }

  .viewbook__tasks {
    margin-top: 30px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: column;
    /* gap: 25px; */
  }
  
  .viewbook__task{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
  }