.reportCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: "white";
  border-radius: 10px;
  width: 210px;
  padding: 25px 22px;
  text-align: center;
  border: 0.5px solid var(--grey300);
}

.reportCard h5 {
  font-weight: 600;
}

.reportCard h6 {
  text-overflow: ellipsis;
  line-clamp: 3;
  word-wrap: break-word;
  overflow: hidden;
  height: 55px;
}

.reportCard img {
  height: 40px;
  filter: invert(47%) sepia(60%) saturate(6836%) hue-rotate(214deg)
    brightness(86%) contrast(88%);
}
