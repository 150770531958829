.addTopics {
  width: 90%;
  display: flex;
  flex-direction: column;
  gap:25px
  /* align-self: center; */
}

.addTopics__form h5 {
  color: var(--grey500);
  margin: 20px 0 3px;
}

.topic__actions {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.addTopics__form h4 {
  margin-bottom: 3px;
}

.ql-editor .ql-editor-handle {
  background-color: gray;
  width: 8px;
  height: 100%;
  right: 0;
  top: 0;
  position: absolute;
  opacity: 1;
  cursor: col-resize;
  z-index: 10;
}
