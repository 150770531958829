.adminLayout {
  background-image: url('../../../assets/Bg.png');
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
}
.dashboard__actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.dashboard__headers {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.dashboard__content {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 5px;
  padding-top: 30px;
}
