.org {
  padding: 20px 35px 20px 45px;
  background-color: white;
  height: calc(100vh - 100px);
  overflow-y: auto;
}

.org__title {
  width: 100%;
  background-color: var(--slightpurple);
  border-radius: 3px;
  margin: 10px 0px;
  border-radius: 20px 20px 0 0px;
}
.org__title h1 {
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--pblue);
}

.field label {
  font-size: 15px;
}
.field {
  display: grid;
  grid-template-columns: 20vw 1fr;
  gap: 60px;
  border: 1px solid var(--grey300);
  padding: 50px;
  border-radius: 5px;
}

.field2 {
  display: grid;
  grid-template-columns: 47% 47%;
  gap: 6%;
  border: 1px solid var(--grey300);
  padding: 50px;
  border-radius: 5px;
}

.field__detail {
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  margin-bottom: 15px;
}
.field__detail label {
  margin-top: 5px;
  font-size: 16px;
  color: var(--grey500);
}

.field h5 span,
.field__detail label span {
  color: var(--error);
  margin-left: 1px;
}

.dropzone-wrapper {
  border: 1px dashed var(--grey400);
  border-radius: 10px;
  margin-top: 10px;
  color: var(--grey300);
  position: relative;
  height: 160px;
  width: 100% !important;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 50%;
  /* top: 50px; */
  font-size: 16px;
}
.dropzone-image {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  /* align-items: center; */
  height: 90%;
  width: 100%;
  position: relative;
}

.dropzone-image img {
  position: relative;
  max-width: 300px;
  height: 130px;
  object-fit: cover;
  margin-top: 5px;
  border-radius: 3px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #f5f5f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.owner__action {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-top: 30px;
}

.owner__cross {
  position: absolute;
  right: 10px;
  top: 30px;
}
