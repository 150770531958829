.vieworg {
    padding: 20px 35px 20px 45px;
    background-color: white;
    height: calc(100vh - 100px);
    overflow-y: auto;
}

.vieworg__breadcrumbs h4,
.vieworg__edit h4 {
    color: var(--pblue);
}

.vieworg__breadcrumbs:hover,.vieworg__edit:hover{
    cursor: pointer;
}

.vieworg__edit {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
}


.vieworg__container {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.vieworg__details {
    border: 1px solid #BDBDBD;
    border-radius: 20px;
    width: 100%;
}

.vieworg__details h4 {
    color: var(--pblue);
    background-color: var(--slightpurple);
    padding: 10px 30px;
}

.vieworg__companydetails {
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 30px 50px;
    gap: 50px;
    align-items: center;
}

.vieworg__companyleft{
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.vieworg__companyleft img {
    padding: 10px;
    border: 1px solid var(--grey200);
    border-radius: 10px;
    width: 150px;
    height: 120px;
    object-fit: contain;
}

.vieworg__companyright{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    gap: 25px;
}

.vieworg__datapoint{
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.vieworg__contactdetails{
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 30px 50px;
    align-items: center;
}

.vieworg__contactside{
    display: flex;
    flex-direction: row;
    align-content: flex-start;
    gap: 25px;
}