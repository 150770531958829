.addTasks {
  width: 100%;
  /* padding: 0px 16px; */
  /* padding: 25px;
  border-radius: 10px;
  width: 96%; */
}

.addTasks h6, .addTasks span {
  color: var(--pblue);
  margin-bottom: 20px;
  /* margin: 20px 0; */
}

.addTasks span:hover{
  cursor: pointer;
}

.task__points {
  margin-top: 10px;
}
.task__points h4 {
  font-size: 13px;
}

.task__actions {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
