.editbooks__left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.editbooks {
  display: grid;
  grid-template-columns: 1fr auto auto;
  align-content: flex-start;
  gap: 30px;
  margin-top: 20px;
}

.editbooks__right {
  width: 190px;
  height: 175px;
  border: 1.5px dashed var(--grey300);
  border-radius: 5px;
  padding: 15px;
  position: relative;
}

.editbooks__points h4 {
  font-weight: 900;
  color: white;
  text-align: center;
  font-size: 16px;
}

.editbooks__right h6 span {
  color: var(--error);
}

.editbooks__right img {
  width: 90%;
  height: 100%;
  /* max-width: 160px;
  max-height: 160px; */
  object-fit: contain;
  overflow: hidden;
  /* margin-top: 4px; */
  border-radius: 3px;
  display: flex;
  margin: auto;
}

.editbooks__right__header {
  display: flex;
  justify-content: space-between;
}

.editbooks__theme{
  width: 240px;
  height: 175px;
  border: 1.5px dashed var(--grey300);
  border-radius: 5px;
  padding: 15px;
  position: relative;
}

.editbooks__theme img{
  width: 100%;
  height: 100%;
  /* max-width: 160px; */
  /* max-height: 160px; */
  object-fit: contain;
  overflow: hidden;
  /* margin-top: 4px; */
  border-radius: 3px;
}

.editbooks__save {
  display: flex;
  justify-content: flex-end;
  margin: 10px 0 10px 0;
  width: 100%;
  gap: 15px;
}

.editbooks__save button {
  width: max-content;
}

.editbooks__point_badge {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: #ee723333;
  width: fit-content;
  color: #ee7233;
  font-weight: 600;
  border-radius: 5px;
  margin-top: 15px;
}

.editbooks__point_badge h5 {
  color: #ee7233;
  font-weight: 600;
}

.editbooks__cross {
  position: absolute;
  right: 20px;
  top: 45px;
  cursor: pointer;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.7);
}

.editbooks__cross:hover {
  scale: 1.1;
}

.editbooks__container {
  padding-right: 15px;
  overflow-x: hidden;
  margin-bottom: 25px;
}
.editbooks__content {
  display: grid;
  grid-template-columns: 300px 1fr;
  /* align-content: flex-start; */
  border-radius: 10px;
  border: 0.5px solid var(--grey300);
  /* gap: 30px; */
  margin: 40px 0 40px;
  height: 660px;
  /* margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 60px; */
}
.editbooks__contentLeft {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--slightblue);
  padding: 25px 15px 25px 15px;
  gap: 16px;
  overflow-y: auto;
}

.editbooks__level h4 {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.editbooks__contentRight {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  padding: 25px 20px;
  background-color: white;
  overflow-y: auto;
  overflow-x: hidden;
}

.editbooks__task {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 8px;
  border: 0.5px solid var(--grey300);
}

.editbooks__taskDrawer {
  align-self: flex-end;
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.task__drawer{
  background-color: white;
  border-radius: 4px;
  border: 0.5px solid var(--grey300);
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-right: 50px;
  /* margin-right: -1px; */
}

.task__drawer p {
  padding: 8px 10px;
  display: flex;
  align-items: center;
  gap: 8px;
  /* font-size: 15px; */
}

.task__drawer p:hover {
  cursor: pointer;
  border-radius: 2px;
  color: var(--pblue);
}

.editbooks__accordians {
  max-width: 2000px;
  border: 1px solid var(--grey300);
  border-radius: 10px;
  height: 56px;
  transition: height 0.6s;
}

.editbooks__accordiansActive {
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  padding: 24px 48px;
  /* gap: 8px; */
  border-radius: 12px;
  border: 0.5px solid var(--grey300);
  height: 660px;
}
.editbooks__accordiansActive h5, .editbooks__contentRight h5{
  color: var(--pblue);
}

.editbooks__accordiansActive h4{
  padding-top: 16px;
}

.editbooks__level {
  /* background-color: var(--slightpurple); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  /* padding: 4px 8px; */
  height: 35px;
  /* gap: 60%; */
  width: 100%;
}

.editbooks__more {
  position: absolute;
  right: 37px;
  cursor: pointer;
  top: 10px;
  z-index: 10;
}

.edit_svg_level path {
  stroke-width: 1px;
}

.editbooks__accHead h5 {
  color: var(--pblue);
}

.editbooks__accordiansForm {
  /* padding: 20px 40px; */
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.dropzone-wrapper {
  border: 1px dashed var(--grey400);
  border-radius: 10px;
  margin-top: 10px;
  color: var(--grey300);
  width: 60%;
  position: relative;
  height: 160px;
  display: flex;
  align-items: center;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  /* top: 50px; */
  font-size: 16px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #f5f5f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}

.editbooks__actions {
  display: flex;
  justify-content: flex-end;
}

.editbooks__levels {
  margin-top: 30px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  gap: 25px;
}

.editbooks__levelContainer {
  /* height: 35px; */
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* align-items: flex-end; */
  /* flex-direction: column; */
  /* align-items: end ; */
}

.editbooks__levelAction {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 10px;
  margin-top: 15px;
  align-items: center;
}

.editbooks__badge_select_container {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  gap: 20px;
  margin-top: 35px;
}
.editbooks__badge_templates {
  width: 40%;
  border: 0.1px solid #c5c5c5;
  border-radius: 10px;
}
.editbooks__badge_editor {
  width: 60%;
  border: 0.1px solid #c5c5c5;
  border-radius: 10px;
  padding: 20px;
}

/* .batch {
  height: calc(100vh - 150px);
  overflow-y: auto;
} */

.dropzone-desc-edit {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  gap: 10px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 170px;
  cursor: pointer;
  opacity: 0;
}
