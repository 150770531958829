* {
  margin: 0;
}

/* light mode */
:root {
  /* primary */
  --pmain: #ee7233;
  --pwhite: #ffffff;
  --pblue: #3361cf;

  /* secondary */

  --sblue: #1e4ab6;
  --syellow: #ffbe18;
  --sstar: #ee7233;
  --spurple: #8a73db;
  --sgreen: #99c551;
  --slightYellow: #fae9be;

  --slightpurple: #e0e7f8;
  --slightblue: #3361cf09;
  --slightgrey: #f5f5f5;

  /* Grey shades for light mode */
  --grey100: #e6e6e6;
  --grey200: #d9d9d9;
  --grey300: #c3c3c3;
  --grey400: #747474;
  --grey500: #5c5c5c;
  --grey600: #444444;
  --grey700: #8e8e8e;

  /* Alert */
  --success: #05c801;
  --error: #ff0000;
}

/* dark mode */
/* .dark { */
/* primary */

/* --pwhite: #1e1f23; */

/* secondary */

/* --slightpurple: #21293d;
  --slightgrey: #0f0f0f; */

/* Grey shades for dark mode */
/* --grey100: #d9d9d9;
  --grey200: #c3c3c3;
  --grey300: #818181;
  --grey400: #747474;
  --grey500: #1e1f23;
  --grey600: #0f0f0f; */
/* } */

body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* font-weight: 400; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  line-height: normal;
  font-weight: 500;
}

h1 {
  font-size: clamp(20px, 1vw, 22px);
  color: var(--grey600);
  line-height: normal;
}

h2 {
  font-size: clamp(18px, 1vw, 20px);
  color: var(--grey600);
  line-height: normal;
}
h3 {
  font-size: clamp(16px, 1vw, 18px);
  color: var(--grey600);
  line-height: normal;
}
h4 {
  font-size: clamp(14px, 1vw, 16px);
  line-height: normal;
  font-weight: 500;
  color: var(--grey500);
}
h5 {
  font-size: clamp(13px, 1vw, 14px);
  line-height: normal;
  color: var(--grey500);
}
h6 {
  font-size: clamp(12px, 1vw, 13px);
  line-height: normal;
  color: var(--grey500);
}
p {
  font-size: clamp(14px, 1vw, 20px);
  line-height: normal;
  color: var(--grey500);
}
span {
  font-size: clamp(12px, 1vw, 16px);
  line-height: normal;
  color: var(--grey500);
}
caption {
  font-size: clamp(10px, 1vw, 14px);
  line-height: normal;
  color: var(--grey600);
}

.ftwt__r {
  font-weight: 500;
}
.ftwt__b {
  font-weight: 600;
}

/* li {
  font-size: clamp(16px, 1.2vw, 20px);
} */

/* dark mode text
.dark h1,
.dark h2,
.dark h3 {
  color: var(--grey100);
}
.dark h4,
.dark h5,
.dark h6,
.dark p,
.dark span,
.dark caption {
  color: var(--grey200);
} */

*::-webkit-scrollbar {
  background: transparent; /* make scrollbar transparent */
  -webkit-appearance: none;
  width: 7px;
  height: 7px !important;
  scrollbar-width: thin;
}

*::-webkit-scrollbar-thumb {
  height: 5px !important;
  border-radius: 2px;
  background-color: rgb(153, 153, 153);
  scrollbar-width: thin;
}

*::-webkit-scrollbar-thumb:hover {
  border-radius: 3px;
  background-color: var(--grey400);
}
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px var(--grey200);
  border-radius: 10px;
  background-color: var(--grey100);
}

.container {
  padding: 0px 35px 0px 45px;
  margin-top: 20px;
}
.containerlogin {
  padding: 0px 80px;
}

@media (prefers-color-scheme: dark) {
  :root {
    --breadcrumbsbg: black;
  }
}
.seprator {
  height: 0.5px;
  background-color: var(--grey300);
  opacity: 60%;
  width: 100%;
}

.vertical__seprator {
  height: 30px;
  background-color: var(--grey300);
  opacity: 60%;
  width: 1px;
}

.disableDesktop {
  display: none;
}

/* Mui buttons */

.MuiButton-root {
  font-size: clamp(15px, 1vw, 19px) !important;
  padding: 8px 15px !important;
  text-transform: capitalize !important;
  line-height: 1.4 !important;
  font-family: "Montserrat", sans-serif !important;
  font-weight: 500 !important;
  font-size: 13px !important;
}

.MuiOutlinedInput-input {
  padding: 10px 8px !important;
  font-size: 14px !important;
}
.MuiOutlinedInput-root {
  padding: 0px 8px !important;
  border-radius: 5px !important;
  font-size: 14px !important;
  background-color: white !important;
}

/* Components */
.contained__deplgbtn {
  font-weight: 400;
  font-size: 14px;
  outline: none;
  border: none;
  text-transform: capitalize;
  padding: 10px;
  width: 100%;
  border-radius: 5px;
  color: var(--pblue);
  font-family: "Montserrat", sans-serif;
  background-color: var(--slightpurple);
  cursor: pointer;
  font-weight: 600;
}
.contained__deplgbtn:hover {
  color: white;
  background-color: var(--pblue);
}

.outlined__btn:hover {
  background-color: var(--sblue);
  color: var(--pwhite);
  border: 1px solid var(--sblue);
}

.contained__lgbtn {
  font-weight: 400;
  font-size: 14px;
  outline: none;
  border: none;
  text-transform: capitalize;
  padding: 10px;
  border-radius: 5px;
  color: var(--pblue);
  font-family: "Montserrat", sans-serif;
  background-color: var(--slightpurple);
  cursor: pointer;
  font-weight: 600;
}

.contained__lgbtn {
  font-weight: 400;
  font-size: 14px;
  outline: none;
  border: none;
  text-transform: capitalize;
  padding: 10px 15px;
  border-radius: 5px;
  color: var(--pblue);
  font-family: "Montserrat", sans-serif;
  background-color: var(--slightpurple);
  cursor: pointer;
  font-weight: 600;
}
.contained__lgbtn:hover {
  color: white;
  background-color: var(--pblue);
}
.contained__lgbtn1 {
  font-weight: 600;
  font-size: 14px;
  outline: none;
  border: none;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  padding: 8px 40px;
  border-radius: 5px;
  color: white;
  background: linear-gradient(180deg, #a0a0a0 0%, #727885 64.06%);

  cursor: pointer;
}
.contained__lgbtn2 {
  font-weight: 600;
  font-size: 14px;
  outline: none;
  border: none;
  font-family: "Montserrat", sans-serif;
  text-transform: capitalize;
  padding: 8px 20px;
  border-radius: 5px;
  color: #1e2128;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  cursor: pointer;
}

.dark .contained__lgbtn2 {
  color: white;
  background-color: #1e2128;
}

.booksbtn {
  font-weight: 400;
  font-size: clamp(12px, 1vw, 14px);
  outline: none;
  border: none;
  font-size: 16px;
  text-transform: capitalize;
  padding: 8px 15px;
  border-radius: 5px;
  color: white;
  background-color: #40485a;
  cursor: pointer;
}

.booksbtnStart {
  font-weight: 400;
  outline: none;
  border: none;
  font-size: 13px;
  display: flex;
  gap: 4px;
  align-items: center;
  text-transform: capitalize;
  padding: 8px 15px;
  border-radius: 5px;
  color: white;
  background-color: var(--pblue);
  cursor: pointer;
}

.booksbtnopen {
  font-weight: 400;
  font-size: clamp(20px, 1vw, 22px);
  outline: none;
  border: none;
  text-transform: capitalize;
  padding: 10px 32px;
  border-radius: 5px;
  color: white;
  background-color: #40485a;
  cursor: pointer;
}
/* .dark .contained__lgbtn {
  color: var(--grey100);
  background-color: var(--grey500);
} */

/* sort */

/* SORT */
.main__sort span {
  background-color: var(--pwhite);
  padding: 11px 12px;
  font-size: clamp(14px, 1vw, 20px);
  border: 0.5px solid var(--grey400);
  color: var(--grey400);
  border-radius: 5px;
}

.main__sortitems {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 120px;
  padding: 2px 14px;
  background-color: var(--pwhite);
  border-radius: 5px;
  border: 0.5px solid var(--grey300);
  cursor: pointer;
  z-index: 1;
  transition: height 0.5s;
}

.main__sortitemsActive p,
.main__sortitems p {
  /* font-size: 14px; */
  padding: 5px 12px;
  cursor: pointer;
  color: var(--grey400);
  text-transform: capitalize;
}
.main__sortitemsActive p:hover,
.main__sortitems p:hover {
  background-color: var(--slightgrey);
  border-radius: 5px;
}
.main__sortselect {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
}
.mainsortmain {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mainsortmain img {
  width: 13px;
}
.main__sortitemsActive .mainsortmain img {
  transform: rotate(180deg);
}

.main__selection {
  display: none;
}

.main__sortitemsActivecont {
  position: relative;
  /* z-index: 1; */
  width: 150px;
}
.main__sortitemsActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  z-index: 10;
  width: 120px;
  padding: 2px 14px;
  height: 140px;
  background-color: var(--pwhite);
  border-radius: 5px;
  border: 0.5px solid var(--grey300);
  margin-top: -19px;
  transition: height 0.5s;
}

.main__sortitemsActive .main__selection {
  display: unset;
}
.main__sortseprator {
  height: 0.5px;
  background-color: var(--grey300);
  opacity: 60%;
  width: 100%;
  margin: 10px 0;
}

.main__sort {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}

.main__contentHead {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  text-transform: capitalize;
  margin-top: 30px;
}

.main__contentHead p {
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
}
.main__seprator {
  height: 0.5px;
  background-color: var(--grey300);
  opacity: 60%;
  width: 100%;
}

/*  Rollover image styles  */
.figure {
  position: relative;
}
.figure img.Sirv.image-hover {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  object-fit: contain;
  opacity: 0;
  transition: opacity 0.2s;
  cursor: pointer;
}
.figure:hover img.Sirv.image-hover {
  opacity: 1;
}

.margin-separator {
  margin: 25px 0;
}

.truncate-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: strict;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.truncate-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: strict;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

.truncate-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: strict;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.truncate-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: strict;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.truncate-5 {
  overflow: hidden;
  text-overflow: ellipsis;
  line-break: strict;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  line-clamp: 5;
  -webkit-box-orient: vertical;
}
@media screen and (max-width: 912px) {
  .disableTablet {
    display: none;
  }
  .disableDesktop {
    display: unset;
  }
  .containerlogin {
    padding: 0 50px;
  }
}

@media screen and (max-width: 480px) {
  .disableMobile {
    display: none;
  }
  .containerlogin {
    padding: 0 20px;
  }
}

@media screen and (max-width: 380px) {
  .disableMobile {
    display: none;
  }
  .containerlogin {
    padding: 0 10px;
  }
}

.container__books {
  padding: 85px 0px 40px;
  width: 80vw;
  margin: 0 auto;
  max-width: 1600px;
}
.container__accounts {
  padding: 70px 0 0px 0;
  margin: 0 auto;
}
.container__mact {
  padding-top: 70px !important;
  margin: 0 auto;
  color: white;
}

input {
  font-family: "Montserrat", sans-serif;
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 32px;
  height: 16px;
  background: grey;
  border-radius: 100px;
  position: relative;
  transition: background-color 0.2s;
}

.switch-label .switch-button {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 12px;
  height: 12px;
  border-radius: 45px;
  transition: 0.2s;
  background: #fff;
  box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 2px);
  transform: translateX(-100%);
}

.switch-label:active .switch-button {
  width: 15px;
}

.ql-tooltip {
  transform: translateY(0px) translateX(50%) !important;
}

.tooltip {
  position: absolute;
  width: max-content;
  font-size: 14px;
  padding: 5px 8px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  top: -55px;
  visibility: visible;
  pointer-events: auto;
  background: #ffa318;
  color: #3361cf;
  z-index: 1;
}

.tooltip::after {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  background: #ffa318;
  bottom: -3px;
  left: 50%;
  z-index: -1;
  transform: translate(-50%) rotate(45deg);
  transition: all 0.5s ease-in-out;
  -webkit-transform: translate(-50%) rotate(45deg);
  -moz-transform: translate(-50%) rotate(45deg);
  -ms-transform: translate(-50%) rotate(45deg);
  -o-transform: translate(-50%) rotate(45deg);
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
}

.tooltip.tooltip-inactive,
.tooltip.tooltip-inactive::after {
  background: var(--grey400);
  color: white;
}

.local_dp {
  z-index: 1000;
}

.date_range_cont {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: thin solid var(--grey300);
  border-radius: 5px;
  padding-left: 15px;
}
.date_range_cont:hover {
  background-color: #f2f6fc;
  border: thin solid var(--pblue);
}
.date_range_cont:hover .local_dp div.react-daterange-picker__wrapper {
  border-color: transparent;
}

.local_dp div.react-daterange-picker__wrapper {
  padding: 1.5px 7px;
  border-radius: 5px;
  border-color: white;
}

.local_dp div#temp-placeholder {
  padding: 5.8px 0;
}

.local_dp div {
  /* padding: 3.7px !important; */
  border-radius: 5px;
  border-color: #c1c1c1;
}

.react-daterange-picker__inputGroup {
  display: contents;
}

.react-daterange-picker__inputGroup * {
  font-size: 13px !important;
}

.react-daterange-picker__range-divider {
  padding: 0 10px;
}

.react-daterange-picker__inputGroup #temp-placeholder-0 {
  padding: 0 0 0 10px;
}

.react-daterange-picker__inputGroup #temp-placeholder-1 {
  padding: 0 10px 0 0;
}

.dp_calender_icon {
  color: gray !important;
}

.home__resumeSecond {
  display: flex;
}

.moving-text:hover {
  animation-play-state: paused;
}

/* The animation */
@keyframes marquee {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}
@keyframes marquee1 {
  0% {
    transform: translateX(150%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* media query to enable animation for only those who want it */
@media (prefers-reduced-motion: no-preference) {
  .moving-text {
    animation: marquee 15s linear infinite;
  }
  .moving-text1 {
    animation: marquee1 15s linear infinite;
  }
}

.quill iframe {
  width: 100%;
  height: 100%;
}

.readOnly_quill .ql-toolbar {
  border: none !important;
  padding: 0 !important;
}

.readOnly_quill .ql-container {
  border: none !important;
  padding: 0 !important;
  height: auto;
}

.readOnly_quill .ql-container .ql-editor {
  border: none !important;
  padding: 0 !important;
  height: auto;
}

.readOnly_quill iframe {
  width: 100% !important;
  height: 100% !important;
}

.react-calendar__navigation__next-button:hover,
.react-calendar__navigation__prev-button:hover {
  background-color: var(--pblue) !important;
}

.react-calendar__navigation__next-button:hover svg,
.react-calendar__navigation__prev-button:hover svg {
  color: white;
}

/*  */

.react-joyride__tooltip {
  padding: 15px 25px !important;
  font-family: "Montserrat", sans-serif;
}
.react-joyride__tooltip h4 {
  font-size: clamp(20px, 1vw, 22px);
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  color: var(--pblue);
  text-align: left;
}

.react-joyride__tooltip div {
  padding: 0px !important;
  text-align: left;
  font-size: clamp(14px, 1vw, 16px);
  color: var(--grey400);
  margin-top: 15px;
}
.react-joyride__tooltip div:nth-child(2) div {
  display: flex;
  align-items: center !important;
  margin: 0px;
  /* background-color: #05c801; */
}

.__floater {
  padding: 10px 0px 0px !important;
}

.react-joyride__tooltip svg {
  width: 11px;
  height: 11px;
}
.react-joyride__tooltip button {
  font-size: clamp(14px, 1vw, 16px);
  font-family: "Montserrat", sans-serif;
}

.react-joyride__tooltip button:nth-child(1) {
  padding: 8px 0px !important;
  font-size: clamp(14px, 1vw, 16px);
  font-family: "Montserrat", sans-serif;
}
@keyframes bounce {
  from {
    transform: translate3d(0, 0, 0);
  }
  to {
    transform: translate3d(0, 5%, 0);
  }
}

.bounce {
  animation: bounce 0.5s;
  animation-direction: alternate;
  animation-iteration-count: infinite;
}

.MuiTableContainer-root {
  border-top-left-radius: 20px;
}
