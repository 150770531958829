.modcon {
  display: flex;
  flex-direction: column;
  /* gap: 15px; */
  padding: 20px 70px 15px;
  height: cal(100vh - 40px);
  margin-top: 70px;
  /* overflow-y: auto; */

  background-color: white;
}
.modcon__completed {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modcon__top {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 20px;
}
.modcon__topbtn {
  margin-left: auto;
}

/* .modcon__que,
.modcon__desc h3 {
  margin: 20px 0;
} */

.modcon__img img {
  /* max-width: 40vw; */
  width: 100%;
  max-width: 45vw;
}

.modcon__topiccontainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.modcon__desc {
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* max-width: 80vw; */
  margin-right: 10px;
  align-items: flex-start;
  overflow-y: auto;
  padding-top: 20px;
  height: 80%;
}
.modcon__desc img{
  max-width: 55vw;

}

.modcon__middle {
  height: calc(100vh - 225px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  padding-bottom: 30px;
  gap: 15px;
  justify-content: space-between;
  align-items: flex-start;
}

.modcon__quizend {
  border-radius: 8px;
  border: 1px solid var(--grey200);
  padding: 20px 20px;
  width: 500px;
  /* height: 300px; */
  margin-top: 50px;
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.modcon__bottom {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-right: 10px;
  position: "sticky"
}
.course__breadcrumbs {
  display: flex;
  align-items: center;
  gap: 10px;
}
.modcon__action {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.course__breadcrumbshome {
  border-radius: 50%;
  width: 22px;
  border: 1.5px solid var(--pblue);
  height: 22px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.course__breadcrumbshome:hover {
  background-color: var(--pblue);
}

.modcon__action h5 {
  color: var(--pblue);
  cursor: pointer;
}

/* buttons */

.course__actions {
  display: flex;
  justify-content: center;
  align-items: center;
}

.course__prev,
.course__next, .course__prevDA, .course__nextDA {
  background-color: white;
  border: 1px solid var(--pblue);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}


.course__prev{
  border-right: 0.5px solid var(--pblue);
}
.course__next{
  border-left: 0.5px solid var(--pblue);
}
.course__prevDA, .course__nextDA{
  background-color: white;
  border: 1px solid var(--grey200);
}

.course__prev, .course__prevDA {
  border-radius: 20px 0px 0px 20px;
  padding: 1px 5px;
}

.course__next, .course__nextDA {
  border-radius: 0px 20px 20px 0px;
  padding: 1px 5px;
}

.course__prev:hover,
.course__next:hover {
  background-color: var(--pblue);
  border: 1px solid var(--pblue);
}
.course__prev:hover .MuiSvgIcon-root,
.course__next:hover .MuiSvgIcon-root
{
color: white !important;
}
.course_content__desc iframe {
  width: 100%;
  aspect-ratio: 16 / 9;
}
.course_content__resources {
  display: flex;
  /* justify-content: center; */
  flex-wrap: wrap;
  gap: 60px;
  padding: 20px 0px;
}
