.submodules {
    display: flex;
    /* align-items: center;
    justify-content: center; */
    flex-direction: column;
    width: 90%;
    border-radius: 8px;
    border: 0.5px solid var(--grey300);
}

.submodules__type {
    background-color: var(--slightblue);
    padding: 8px;
    display: flex;
   justify-content: space-between;
}

.submodules__title {
    padding: 8px 18px;
}

.submodule__drawer{
    background-color: white;
    width: 100px;
    border-radius: 4px;
    border: 0.5px solid var(--grey300);
    display: flex;
    flex-direction: column;
    /* margin-right: -1px; */
    gap: 1px;
    /* position: fixed; */
}

.submodule__drawer p {
    padding: 8px 10px;
    display: flex;
    align-items: center;
    gap: 8px;
    /* font-size: 15px; */
  }
  
  .submodule__drawer p:hover {
    width: 80px;
    border-radius: 2px;
    color: var(--pblue);
  }