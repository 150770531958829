.quiz {
    gap: 25px;
    width: 90%;
    display: flex;
    flex-direction: column;
}

.quiz__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-end;
}

.quiz__qcontainer {
    border: 0.5px solid var(--grey300);
    border-radius: 8px;
    /* width: 90%; */
}

.quiz__qTopView {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border-left: 5px solid var(--pblue);
}


.quiz__qTitle {
    background-color: var(--slightblue);
    padding: 8px;
    display: flex;
    justify-content: space-between;
}

.quiz__q {
    padding: 8px;

}

.quiz h6 {
    color: var(--pblue);
    /* margin: 0 0 25px; */
}

.quiz_actions {
    display: flex;
    flex-direction: row;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    /* width: 20%; */
    /* align-self: flex-end; */
}

.quiz_actionsLeft {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
}

.quiz_actionsRight {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.quiz__qEdit {
    display: flex;
    flex-direction: column;
    gap: 15px;
    border-left: 5px solid var(--pblue);
    border-radius: 8px;
    padding: 15px;
}

.quiz_qEditTitle {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.quiz_qoptions {
    background-color: var(--slightblue);
    border: 1px solid var(--pblue);
    width: 32px;
    height: 32px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.quiz__choice {
    padding: 4px 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: "100%";
    gap: 10px;
}

.quiz__choice:hover{
    border-bottom: 0.5px solid #C3c3c3;
    display: flex;
    justify-content: space-between;
}

.quiz__choice:hover .quiz__removeoption{
    display: flex;
    justify-self: flex-end;
}

.quiz__removeoption {
    display: none;
}