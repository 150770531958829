.sidebar {
  height: calc(100vh - 70px);
  /* background: rgba(68, 68, 68, 0.3); */
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  backdrop-filter: blur(15px);
  padding: 0px 17px 0px 17px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.dark .sidebar {
  border-right: 1px solid var(--grey100);
}
/* 
.dark .sidebar {
   height: calc(100vh - 70px);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.5);
   backdrop-filter: blur(13px);
  background: rgba(68, 68, 68, 0.3);
  padding: 0px 17px 0px 17px;
  display: flex;
  flex-direction: column;
} */

.sidebar__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
}

.sidebar__logo img {
  width: 150px;
}

.sidebar__logoClose {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__logoClose img {
  width: 26px !important;
  height: 29px;
}

.sidebar__seprator {
  margin: 42px 0;
}

.sidebar__switch {
  position: absolute;
  top: calc(50vh - 70px);
  z-index: 2;
  right: -12px;
}
.sidebar__layout {
  position: relative;
}

.sidebar__switch .MuiIconButton-root {
  background-color: var(--grey300) !important;
  font-size: 15px !important;
}

.dark .sidebar__switch .MuiIconButton-root {
  background-color: var(--grey600) !important;
  font-size: 15px !important;
}

.sidebar__links {
  width: 85%;
}

.sidebar__link {
  list-style-type: none;
  margin-bottom: 15px;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 8px 8px;
  background-color: inherit;
  gap: 10px;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sidebar__linkInfo {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
}

.sidebar__icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar__iconwhite {
  /* filter: brightness(0) invert(1); */
  
  filter: brightness(0) invert(100%) hue-rotate(220deg);

  width: 22px;
}

.dark .sidebar__iconwhite {
  filter: unset;
  width: 22px;
}
.sidebar__icongrey {
  width: 22px;

  /* filter: brightness(0) invert(0.5); */
}

.dark .sidebar ul li,
.dark .sidebar__icongrey {
  filter: unset ;
}

/* .sidebar ul li {
  margin-left: ;
} */

.sidebar ul li {
  color: var(--grey100);
  font-weight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  width: 225px;
  height: 25px;
}
.sidebar ul li pre {
  width: 148px;
}
.sidebar ul li:hover {
  /* background-color: rgba(51, 97, 207, 0.15); */
  background: rgba(217, 217, 217, 0.15);
  color: white;
  border-radius: 5px;
  /* font-weight: 600; */
  width: 225px;
}

.dark .sidebar ul li:hover {
  /* background-color: rgba(51, 97, 207, 0.15); */
  background: rgba(255, 255, 255, 0.75);
  border-radius: 5px;
  /* font-weight: 600; */
  width: 225px;
}
.dark .sidebar ul li:hover pre,
.sidebar .sidebar__child:hover pre {
  color: var(--pblue);
}

.sidebar .sidebar__child:hover {
  /* background-color: rgba(51, 97, 207, 0.15); */
  background: rgba(217, 217, 217, 0.15);
  color: white;
  border-radius: 5px;
  /* font-weight: 600; */
  /* width: 100%; */
}

.sidebar__active {
  /* background-color: rgba(51, 97, 207, 0.15); */
  background: rgba(217, 217, 217, 0.15);
  color: white;
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
}

.dark .sidebar__active {
  /* background-color: rgba(51, 97, 207, 0.15); */
  background: rgba(255, 255, 255, 0.75);
  color: var(--pblue);
  border-radius: 5px;
  font-weight: 600;
  width: 100%;
}
.dark .sidebar ul li:hover,
.dark .sidebar__active {
  /* background-color: var(--grey500); */
  color: var(--pblue) !important;
  border-radius: 5px;
  /* font-weight: 600 !important; */
}

.dark .forum__link:hover span {
  color: var(--pblue);
}

.dark .sidebar ul li {
  color: var(--grey600);
  font-weight: 500;
  font-size: 15px;
  width: 225px;
}
.sidebar__content {
  display: flex;
  flex-grow: 1;
  height: 100vh;

  overflow-y: auto;
  overflow-x: hidden;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.sidebar___modeSwitch {
  margin-bottom: 50px;
}

.sidebar___modeSwitch .MuiIconButton-root {
  background-color: var(--pwhite) !important;
}

.MuiSwitch-thumb {
  color: var(--pblue);
}

.MuiSwitch-track {
  background-color: var(--grey400) !important;
}

.sidebar ul {
  padding: 0;
}
.sidebar__action {
  margin-left: 30px;
  margin-top: 5px;
}

.sidebar__forums {
  margin-left: 30px;
}

.forum__dropdown {
  color: var(--grey400);
}
.dark .forum__dropdown {
  color: var(--grey100);
}

.forumdd__active {
  color: inherit;
}

.sidebar .sidebar__child {
  color: var(--grey200);
  font-size: 15px;
  /* width: 165px; */
  width: 185px !important;
  height: 25px;
  margin-bottom: 3px;
}

.dark .forum__active span {
  color: var(--pblue);
  font-weight: 600;
}
/* .dark .sidebar .forum__active:hover {
  filter: grayscale(73%);
}

.dark .sidebar .forum__active img {
  filter: brightness(0) invert(45%) hue-rotate(220deg);
}

.dark .sidebar .forum__active:hover img {
  filter: unset;
} */

@media screen and (min-width: 1900px) {
  .sidebar__logo img {
    width: 170px;
  }
  .sidebar__logoClose img {
    width: 30px !important;
    height: 33px;
  }
}

@media screen and (min-width: 2550px) {
  .sidebar__logo img {
    width: 200px;
  }
  .sidebar__logoClose img {
    width: 31px !important;
    height: 35px;
  }
  .sidebar__seprator {
    margin: 37px 0 42px 0;
  }
}

.activemode path {
  fill: var(--pblue);
}
