.owner {
  padding: 20px 35px 0px 45px;
  background-color: white;
}

/* .owner__layout {
  display: flex;
} */

.owner__head {
  display: flex;
  margin: 15px 0px;
  justify-content: space-between;
  gap: 10px;
}

.owner__title {
  width: 100%;
  background-color: var(--slightpurple);
  border-radius: 3px;
  margin: 10px 0px;
  border-radius: 20px 20px 0px 0px;
}
.owner__title h1 {
  padding: 13px 20px;
  font-size: 16px;
  font-weight: 600;
  color: var(--pblue);
}

.owner__seprator {
  height: 1px;
  background-color: var(--grey300);
  width: 100%;
  margin: 10px 0px;
}

.owner__cardLayout {
  display: flex;
  /* flex-direction: row; */
  flex-wrap: wrap;
  gap: 30px;
  padding-bottom: 45px;
  max-height: 60vh;
  overflow-y: auto;
}

.owner__card {
  padding: 15px 15px;
  border: 1px solid var(--grey200);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  border-radius: 10px;
  width: 230px;
}

.owner__card:hover {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.owner__cardDetails {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 10px;
  /* margin-left: 20px; */
}

.owner__cardimg {
  width: 100%;
  padding: 10px 0px;
  border: 1px solid var(--grey200);
  border-radius: 10px;
  display: flex;
  justify-content: center;
}

.owner__cardimg img {
  width: 120px;
  height: 120px;
  object-fit: contain;
  border-radius: 3px;
}

.owner__cardDetailsfields {
  display: flex;
  gap: 10px;
}

.owner__internalOrgs {
  display: flex;
  flex-direction: column;
}