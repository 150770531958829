.selectbook{
    max-height: 400px;
    overflow-y: scroll;
}

.selectbook__header {
    background-color: var(--slightpurple);
    padding: 15px 20px;
    border-radius: 10px 10px 0px 0px;
}

.selectbook__header h4{
    color: var(--pblue);
}

.selectbook__book{
    border: 0.5px solid #D9D9D9;
    border-radius: 10px;
    padding: 5px 0px;
    margin: 5px 0px;
}