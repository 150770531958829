.mact {
  /* display: flex;
    flex-direction: column;
    gap: 10px; */
  height: calc(100vh - 50px);
  overflow-y: auto;
  padding: 0px 5vw 0px 5vw;
  backdrop-filter: blur(5px);
}

.mact__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
  transition: all ease-in-out 0.5s;
  transition-delay: 0.5s;
}

.mact__top h1 {
  color: white;
}

.dark .mact__top h1 {
  color: var(--grey600);
}

.mact__mb20 {
  margin-bottom: 25px;
}

.mact__statcont {
  display: inline-grid;
  gap: 50px;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
}

.mact__statcard {
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: linear-gradient(
    90.07deg,
    rgba(255, 255, 255, 0.216) 1.95%,
    rgba(255, 255, 255, 0.081) 99.97%
  );
  padding: 0px;
  backdrop-filter: blur(12.5px);
  flex-shrink: 0;
}

.dark .mact__statcard {
  border-top-right-radius: 45px;
  border-bottom-right-radius: 45px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  background: linear-gradient(
    90.07deg,
    rgba(100, 100, 100, 0.216) 1.95%,
    rgba(100, 100, 100, 0.081) 99.97%
  );
  padding: 0px;
  backdrop-filter: blur(12.5px);
  flex-shrink: 0;
}

.mact__statcard {
  display: flex;

  justify-content: space-between;
  align-items: center;
  flex-shrink: 0;
}

.mact__statdata {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 15px 20px;
}

.mact__statdata h1 {
  font-size: 28px;
  color: white;
}

.dark .mact__statdata h1 {
  color: var(--grey600);
}

.mact__statdata span {
  font-size: 14px;
  color: white;
}

.dark .mact__statdata span {
  color: var(--grey600);
}

.mact__staticon {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(#8a73db 0%, rgba(255, 190, 24, 0.73) 100%);
  flex-shrink: 0;
  border-radius: 100%;
  border: 3px solid white;
  /* outline: 5px solid white; */
}

.mact__staticon img {
  width: 50px;
  height: 50px;
}

.mact__tabscont {
  width: 100%;
  margin: 64px 0;
}

.mact__tab {
  color: #fff !important;
  margin: 0 !important;
}

.dark .mact__tab {
  color: #3361cf !important;
}

.mact__tabscont .MuiTabs-indicator {
  background-color: #fff;
}

.dark .mact__tabscont .MuiTabs-indicator {
  background-color: #3361cf;
}

.mact__tabscont .MuiBox-root {
  border-color: #3361cf77;
}

.dark .mact__tabscont .MuiBox-root {
  border-color: #74747444;
}

.mact__tabscont .MuiBox-root {
  border-color: #ffffff66;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.mact__fadeIn {
  animation: fadeIn 1s ease-in-out forwards;
}

.mact__fadeOut {
  animation: fadeOut 1s ease-in-out forwards;
}
