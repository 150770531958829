.editmodal{
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.editmodal__header{
    display: flex;
    justify-content: space-between;
}

.editmodal__options{
    display: flex;
    gap: 15px;
    overflow-x: auto;
    padding-bottom: 10px;
}

.editmodal__action{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap:20px;
}